import PhotoSwipeGallery from "./photoswipe/PhotoSwipeGallery";
import Overlay from "./Overlay";
import SliderStarter from "./slider/SliderStarter";
import Polyfill from "./Polyfill";
import EventgalleryLazyload from "./EventgalleryLazyload";
import {forEach} from "../../common/js/Helpers";
import EventsList from "./EventgalleryEventsList";
import Imagelist from "./EventgalleryImageList";
import EventsTiles from "./EventgalleryEventsTiles";
import SquareList from "./EventgallerySquareList";
import TilesCollection from "./EventgalleryTilesCollection";
import GooglePhotosProcessor from "./EventgalleryGooglePhotosProcessor";

(function(Eventgallery){
	"use strict";
	document.addEventListener('DOMContentLoaded', () =>{

		/*
		* GRID LIST OF EVENTS
		*/

		function triggerLazyLoading() {
			EventgalleryLazyload.watchImages();
		}

		forEach(document.querySelectorAll('.eventgallery-events-gridlist'), (container) => {
			let thumbnails = container.querySelectorAll('.event-thumbnails .event-thumbnail');

	        let options = {
	            rowHeightPercentage: 100,
	            imagesetContainer: container.querySelector('.event-thumbnails'),
	            imageset: thumbnails,
	            initComplete: function () {
                    triggerLazyLoading();
	            },
	            resizeStart: function () {

	            },
	            resizeComplete: function () {
					document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
	            }
	        };

	        // initialize the imagelist

	        if (thumbnails.length>0) {
	        	new EventsList(options);
	        }
        });


		/*
		* TILE LIST OF EVENTS
		*/
		forEach(document.querySelectorAll('.eventgallery-events-tiles-list'), (container) => {

	        var options = {
	            imagesetContainer: container.querySelector('.event-thumbnails'),
	            imageset: container.querySelectorAll('.event-thumbnail'),
	            eventgalleryTilesCollection: null,
	            initComplete: function () {
					triggerLazyLoading();

	                var tilesOptions = {
	                    tiles: container.querySelectorAll('.eventgallery-tiles .eventgallery-tile'),
	                    tilesContainer: container.querySelector('.eventgallery-tiles')
	                };
	                options.eventgalleryTilesCollection = new TilesCollection(tilesOptions);
	                options.eventgalleryTilesCollection.calculate();
	                // we need to recalculate the whole thing because it might happen that a font loads
	                // and the size of a tile changes.
					window.addEventListener('load', function(){
	                    options.eventgalleryTilesCollection.calculate();
	                });

	            },
	            resizeStart: function () {

	            },
	            resizeComplete: function () {
	                options.eventgalleryTilesCollection.calculate();
					document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
	            }
	        };

	        // initialize the imagelist
	        new EventsTiles(options);

	    });

		/*
		* TILES LIST OF IMAGES
		*/
		forEach(document.querySelectorAll('.eventgallery-event-tiles-list'), (container) => {
	        let options = {
	            imagesetContainer: container.querySelector('.event-thumbnails'),
	            imageset: container.querySelectorAll('.event-thumbnail'),
	            adjustMode: 'width',
	            eventgalleryTilesCollection: null,
	            initComplete: function () {

	                let tilesOptions = {
	                    tiles: container.querySelectorAll('.eventgallery-tiles .eventgallery-tile'),
	                    tilesContainer: container.querySelector('.eventgallery-tiles')
	                };

                    options.eventgalleryTilesCollection = new TilesCollection(tilesOptions);
                    options.eventgalleryTilesCollection.calculate();

	                // we need to recalculate the whole thing because it might happen that a font loads
	                // and the size of a tile changes.
	                window.addEventListener('load', function(){
	                    options.eventgalleryTilesCollection.calculate();
	                });

					triggerLazyLoading();

	            },
	            resizeStart: function () {

	            },
	            resizeComplete: function () {
	                options.eventgalleryTilesCollection.calculate();
	                document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
	            }
	        };

	        // initialize the imagelist
	        new EventsTiles(options);
    	});

		/*
		* SIMPLE IMAGE LIST
		*/
		forEach(document.querySelectorAll('.eventgallery-event-gridlist'), (container) => {
			let options = {
                imagesetContainer: container.querySelector('.event-thumbnails'),
                imageset: container.querySelectorAll('.event-thumbnail'),
                adjustMode: 'height',
                initComplete: function () {
					triggerLazyLoading();
                },
                resizeStart: function () {

                },
                resizeComplete: function () {
                    document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
                }
            };

            // initialize the imagelist
            new EventsTiles(options);
		});

		/*
		* IMAGE LIST
		*/
		forEach(document.querySelectorAll('.eventgallery-imagelist'), (container) => {
            let options = {
                rowHeight: parseInt(container.getAttribute('data-rowheight')),
                rowHeightJitter: parseInt(container.getAttribute('data-rowheightjitter')),
                firstImageRowHeight: parseInt(container.getAttribute('data-firstimagerowheight')),
                doFillLastRow: container.getAttribute('data-dofilllastrow') === 'true',
                imagesetContainer: container,
                imageset: container.querySelectorAll('.thumbnail'),

                initComplete: function () {
					triggerLazyLoading();
                },
                resizeStart: function () {

                },
                resizeComplete: function () {
                    document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
                }
            };

            // initialize the imagelist
            new Imagelist(options);
        });

		/*
		 * SQUARE SIZED LIST OF IMAGES
		 */
		forEach(document.querySelectorAll('.eventgallery-event-square-list'), (container) => {
			let options = {
				imagesetContainer: container.querySelector('.event-thumbnails'),
				imageset: container.querySelectorAll('.event-thumbnail'),
				adjustMode: 'width',
				initComplete: function () {
					triggerLazyLoading();
				},
				resizeStart: function () {

				},
				resizeComplete: function () {
					document.dispatchEvent(Polyfill.createNewEvent('scroll', null));
				}
			};

			// initialize the imagelist
			new SquareList(options);
		});


        /**
		 * Lightbox init
         * @type {PhotoSwipeGallery}
         */
        Eventgallery.lightbox = new PhotoSwipeGallery();
        Eventgallery.lightbox.initPhotoSwipe();

		if (window.EventGalleryLightboxConfiguration && window.EventGalleryLightboxConfiguration.doTrackImageHits) {
			document.addEventListener('eventgallery-lightbox-changed', (data) => {

				const apiUrl = window.EventGalleryLightboxConfiguration.trackImageHitsUrl
				fetch(apiUrl, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({'id': data.detail.id})
				})

					.catch(error => {
						console.error('Error while tracking event:', error);
					});
			})
		}

        /**
		 * Single Image Page
         */
		forEach(document.querySelectorAll('.singleimage-zoom'), (e) => {
			e.addEventListener('click', (e) => {
				e.preventDefault();
				document.getElementById('bigimagelink').click();
			})
		})

        /**
		 * content overlay (example: checkout review page)
         */
		forEach(document.querySelectorAll('a[data-eventgallery-overlay]'), (el) => {
			let contentId = el.getAttribute('href');
			el.addEventListener('click', (e) => {
				e.preventDefault();
				let overlay = new Overlay();
				overlay.openOverlay(document.getElementById(contentId.replace('#', '')).innerHTML);
			});
		})

        let googlePhotosProcessor = new GooglePhotosProcessor();
        document.dispatchEvent(Polyfill.createNewEvent('eventgallery-images-added'));


        let sliderElements = document.querySelectorAll('div[data-slider="1"]');


        for(let i=0; i<sliderElements.length; i++) {
            let sliderStarter = new SliderStarter(sliderElements[i]);
            let timer = null;
            sliderStarter.start();

            window.addEventListener("resize", () => {
            	if (timer != null) {
            		clearTimeout(timer);
				}
            	timer = setTimeout(() => sliderStarter.start(), 1000);
            }, false);
        }

		/*
		 * Make clicks on Events possible even if the link on the image was not clicked
		 * directly
		 */
		let links = document.querySelectorAll('*[data-eg-link]')
		for(let i = 0; i<links.length; i++) {
			const link = links[i]
			link.addEventListener('click', (e) => {
				if (e.target.nodeName === 'A') {
					return;
				}

				e.stopPropagation();
				e.preventDefault();

				let target = e.currentTarget
				let href = target.getAttribute('data-eg-link')
				if (href) {
					window.location.href = href;
				}

			}, false)
		}
    }); //end domready
})(Eventgallery);

