window.Eventgallery = window.Eventgallery || {};

require('./frontend/js/EventgalleryBehavior.js');

require('./frontend/js/photoswipe/PhotoSwipeGallery.js');
require('./frontend/js/photoswipe/PhotoSwipeGallerySlide.js');

require('./frontend/less/eventgallery.less');
require('./frontend/less/fontawesome.less');


require('./frontend/js/minicart/index');
require('./frontend/js/socialbutton/index');
require('./frontend/js/jsgallery/index');

// Required Core Stylesheet
import "@glidejs/glide/dist/css/glide.core.css";
import "./frontend/sass/glide/glide.theme.scss";

