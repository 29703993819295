import Polyfill from "../Polyfill";
import {Events} from "../minicart/CartEvents";
import {addTouch} from "../touch/Touch";
import Overlay from "../Overlay";
import {
    addUrlHashParameter,
    forEach, getElementHeight,
    getElementWidth,
    getUrlHashParameterValue,
    mergeObjects
} from "../../../common/js/Helpers";
import SizeCalculator from "../EventgallerySizeCalculator";

    /*
     *    Constructor. Starts up the whole thing :-)
     *
     *    This script is free to use. It has been created by http://www.aplusmedia.de and
     *    can be downloaded on http://www.esteak.net.
     *    License: GNU GPL 2.0: http://creativecommons.org/licenses/GPL/2.0/
     *    Example on: http://blog.aplusmedia.de/moo-gallery2
     *    Known issues:
     *    - preloading does not care about initialIndex param
     *    - hovering to a control over the border of the big image will make the other one flickering
     *    - if you enter and leave the control area very quickly, the control flickers sometimes
     *    - does not work in IE6
     *
     *    @param {HTMLelement] The main gallery element
     *    @param {Array} thumbs, An array of HTML elements
     *    @param {HTMLelement} bigImageContainer, the full size image
     *    @param {HTMLelement} pageContainer, If you have several pages, put them in this container
     *    @param {Object} options, You have to pass imagesPerPage if you have more than one!
     */
export class JSGallery2 {
    constructor(galleryElement, thumbs, bigImageContainer, pageContainer, newOptions) {
        this.options = {
            'prevHandle': null,			//if you pass a previous page handle in here, it will be hidden if it's not needed
            'nextHandle': null,			//like above, but for next page
            'countHandle': null,		//handle of the counter variable
            'titleTarget': null,		//target HTML element where image texts are copied into
            'initialIndex': -1,			//which thumb to select after init. you could create deep links with it.
            'maxOpacity': 0.8,			//maximum opacity before cursor reaches prev/next control, then it will be set to 1 instantly.
            'showSocialMediaButton': true,
            'showCartButton': true,
            'showCartConnector': false,
            'cartConnectorLinkRel': '',
            'activeClass': 'thumbnail-active', // the css class for the active thumbnail
            'lightboxRel': 'lightbo2', // the trigger rel for the lightbox script
            'touchContainerSelector' : '#bigimageContainer'
        };

        this.galleryElement = galleryElement;
        this.nav = galleryElement.querySelector('.navigation');
        this.image = galleryElement.querySelector('.image');

        this.options = mergeObjects(this.options, newOptions);

        let pages = pageContainer.children;

        // defines if thumbs are currently running
        this.running = false;
        this.currentPageNumber = 0;

        this.blockKeys = false;
        this.imagesPerFirstPage = pages[0].querySelectorAll('div.ajax-thumbnail-container').length;
        this.imagesPerPage = this.imagesPerFirstPage;

        if (pages.length>1 && pages[1].querySelectorAll('div.ajax-thumbnail-container').length>0)  {
            this.imagesPerPage = pages[1].querySelectorAll('div.ajax-thumbnail-container').length;
        }

        this.thumbs = [];
        for (let i=0; i<thumbs.length; i++) {
            this.thumbs.push(thumbs[i]);
        }
        this.bigImageContainer = bigImageContainer;
        this.bigImage = bigImageContainer.querySelector('img');
        this.pageContainer = pageContainer;

        this.convertThumbs();

        this.lastPage = Math.ceil((this.thumbs.length - this.imagesPerFirstPage) / this.imagesPerPage) + 1;

        let url = document.location.href;
        this.initialIndex = parseInt(getUrlHashParameterValue(url, 'imageno'));
        if (isNaN(this.initialIndex)) {
            this.initialIndex = 0;
        }

        this.createControls();

        this.createGallerySlides();
        this.adjustWidth();
        this.adjustPageHeight();
        this.adjustImageSize();

        this.gotoPage(0);

        if (this.options.initialIndex !== -1) {
            this.unBlockKeys();
            this.selectByIndex(this.options.initialIndex);
        } else if (this.initialIndex !== 0) {

            this.unBlockKeys();
            this.selectByIndex(this.initialIndex);
        }

        window.addEventListener('load', () => {this.resizePage()});
        window.addEventListener('resize', () => {this.resizePage()});
    }

    adjustWidth() {
        let width = this.nav.offsetWidth;
        forEach(this.galleryElement.querySelectorAll('.navigation .page'), (e) => {
            e.style.width = width + 2 + "px";
        })
    };

    /* Method to bring the thumb rel attribute to the right size */
    adjustImageSize() {
        let sizeCalculator = new SizeCalculator();
        let width = getElementWidth(this.bigImageContainer);

        forEach(this.nav.querySelectorAll('#thumbs .ajax-thumbnail'), (thumbnail, index) =>  {


            let originalwidth = thumbnail.getAttribute('data-width');
            let originalheight = thumbnail.getAttribute('data-height');
            let     ratio = originalwidth / originalheight;
            let     height = Math.round(width / ratio);
            let     secret = thumbnail.getAttribute('data-secret');

            let imageUrl
            if (secret !== null) {
                let secret_o = thumbnail.getAttribute('data-secret_o');
                let secret_h = thumbnail.getAttribute('data-secret_h');
                let secret_k = thumbnail.getAttribute('data-secret_k');
                let farm = thumbnail.getAttribute('data-farm');
                let type = thumbnail.getAttribute('data-type');
                let server = thumbnail.getAttribute('data-server');
                let id = thumbnail.getAttribute('data-file');

                 imageUrl = sizeCalculator.getFlickrURL(farm, server, secret, secret_h, secret_k, secret_o, id, width, height, originalwidth, originalheight);
            } else {
                let googleWidth = sizeCalculator.getSize(width, height, ratio);
                imageUrl = sizeCalculator.adjustImageURL(thumbnail.getAttribute('rel'), googleWidth);
            }
            thumbnail.setAttribute('rel', imageUrl);
        });
    };

    /* Method which handles the case the window got resized */
    resizePage() {
        window.clearTimeout(this.eventgalleryAjaxResizeTimer);

        this.eventgalleryAjaxResizeTimer = setTimeout(() => {
            this.adjustWidth();
            this.adjustImageSize();
            this.resetThumbs();
            this.gotoPage(this.currentPageNumber);
            this.adjustPageHeight();

        }, 500);
    };


    createGallerySlides() {
        this.slides = [];
        forEach(this.thumbs, (thumbContainer) => {
            let lightboxLinkElement = thumbContainer.getElementsByTagName('A')[0];
            let title =  decodeURIComponent(lightboxLinkElement.getAttribute('data-description'));
            let slide =    {
                w: lightboxLinkElement.getAttribute('data-width'),
                h: lightboxLinkElement.getAttribute('data-height'),
                src: lightboxLinkElement.getAttribute('href'),
                title: title,
                type: lightboxLinkElement.getAttribute('data-type')
            };
            if (slide.type === 'video' && lightboxLinkElement) {
                if (lightboxLinkElement.dataset.pswpVideoSources) {
                    slide.videoSources = JSON.parse(pswpVideoSources);
                } else if (lightboxLinkElement.dataset.pswpVideoSrc) {
                    slide.videoSrc = lightboxLinkElement.dataset.pswpVideoSrc;
                } else {
                    slide.videoSrc = lightboxLinkElement.getAttribute('href');
                }
            }
            this.slides.push(slide)
        })
    };

    createControls() {
        this.prevLink = document.createElement('a');
        this.prevLink.href='#';
        this.prevLink.classList.add('link','jsgallery-prev');
        this.prevLink.addEventListener('click', (e)=> this.prevImage(e));
        this.prevLink.addEventListener('mouseleave', () => this.mouseLeaveHandler())
        this.prevLink.addEventListener('mouseover', (e) => this.focusControl(e, this.prevLink));

        this.zoomLink = document.createElement('a');
        this.zoomLink.href='#';
        this.zoomLink.classList.add('link','jsgallery-zoom');
        this.zoomLink.addEventListener('click', (e) => this.bigImageClickHandler(e));
        this.zoomLink.addEventListener('mouseleave', (e) => this.mouseLeaveHandler());
        this.zoomLink.addEventListener('mouseover', (e) => this.focusControl(e, this.zoomLink));

        this.nextLink = document.createElement('a');
        this.nextLink.href = '#';
        this.nextLink.classList.add('link','jsgallery-next');
        this.nextLink.addEventListener('click', (e) => this.nextImage(e));
        this.nextLink.addEventListener('mouseleave', () => this.mouseLeaveHandler())
        this.nextLink.addEventListener('mouseover', (e) => this.focusControl(e, this.nextLink));

		this.bigImage.parentElement.appendChild(this.prevLink);
        this.bigImage.parentElement.appendChild(this.zoomLink);
        this.bigImage.parentElement.appendChild(this.nextLink);

        this.bigImage.addEventListener('load', ()=>this.showBigImage());

        if (this.options.showCartButton) {
            this.add2cartLink = document.createElement('a');
            this.add2cartLink.href = '#';
            this.add2cartLink.classList.add('eventgallery-add2cart','eventgallery-openAdd2cart','jsgallery-add2cart');
            this.add2cartLink.innerHTML = '<i class="egfa egfa-2x egfa-cart-plus"></i>';
            this.bigImage.parentElement.appendChild(this.add2cartLink);
            document.dispatchEvent(Polyfill.createNewEvent(Events.rebindAdd2CartButtons, null));
        }

        if (this.options.showCartConnector) {

            this.cartConnectorLink = document.createElement('a');
            this.cartConnectorLink.href = '#';
            this.cartConnectorLink.id = 'ajax-cartconnector';
            this.cartConnectorLink.classList.add('button-cart-connector','jsgallery-cartconnector');
            this.cartConnectorLink.innerHTML = '<i class="egfa egfa-2x egfa-cart-plus"></i>';
            this.cartConnectorLink.setAttribute('rel', this.options.cartConnectorLinkRel);
            this.bigImage.parentElement.appendChild(this.cartConnectorLink);
        }

        if (this.options.showSocialMediaButton) {
            let div = document.createElement('div');
            div.innerHTML = '<a id="ajax-social-media-button" class="social-share-button jsgallery-socialmedia" rel="nofollow" href="#"><i class="egfa egfa-2x egfa-share-alt-square"></i></a>';
            this.socialmediabutton = div.firstChild;
            this.socialmediabutton.addEventListener('click', e => {
                e.preventDefault();
                let href = this.socialmediabutton.getAttribute('data-href');
                let overlay = new Overlay();
                overlay.openOverlay(href);
            });
            this.bigImage.parentElement.appendChild(this.socialmediabutton);
        }

        document.addEventListener('keydown', (e) => this.keyboardHandler(e));

		let swipeleft =  (e) => {
		    this.nextImage(e);
		};

		let swiperight = (e) => {
		    this.prevImage(e);
		};

        let tabaction = (e) => {
            this.bigImage.click();
        };

        addTouch(document.querySelector(this.options.touchContainerSelector),
            swiperight,
            swipeleft,
            tabaction,
            null,
        );

        this.mouseLeaveHandler();
    };

    /**
     * Focuses one control
     *
     * @param {Event} event
     * @param {HTMLElement} control
    */
    focusControl(event, control) {
        control.style.opacity = '1';
    };

    /**
     * Hides the controls.
     */
    mouseLeaveHandler() {
        this.nextLink.style.opacity = '0';
        this.prevLink.style.opacity = '0';
        this.zoomLink.style.opacity = '0';
    };

    /**
     * Handles keyboard interactions.
     * @param {Event} event
     */
    keyboardHandler(event) {


        if (!this.blockKeys && Eventgallery.lightbox?.isOpen() !== true) {
            if (event.keyCode >= 49 && event.keyCode <= 57) {
                this.gotoPage(event.key - 1);
            } else if (event.keyCode == 37) {
                this.prevImage(event);
            } else if (event.keyCode == 39) {
                this.nextImage(event);
            }
        }
    };

    adjustPageHeight() {
        // fix height of the page-container
        let maxHeight = 0;
        forEach(this.pageContainer.children, (page) => {
            let height = page.offsetHeight;
            if (height > maxHeight) {
                maxHeight = height;
            }
        })

        this.pageContainer.style.height = maxHeight +'px';
        this.nav.style.opacity = 1;
    }

    resetThumbs() {
       	this.running = false;

		this.convertThumbs();

        //if we like to select another image on that page than the first one
        this.select(this.selectedContainer, true);
    };

    /**
     *    Adds the border to the thumbs and so on. (conversion of static thumbs)
     */
    convertThumbs() {
        forEach(this.thumbs, (thumbContainer, count)=>{
            this.convertThumb(thumbContainer, count);
        })
    };

    /**
     * Converts one single thumb.
     * @param {HTMLElement} thumbContainer
     * @param {Integer} count
     */
    convertThumb(thumbContainer, count) {

        if (thumbContainer === undefined) {
            return;
        }

        thumbContainer.addEventListener('click', (e) => {
            e.preventDefault();
            this.select(thumbContainer);
        });


        thumbContainer.style.position =  'relative';
        thumbContainer.setAttribute('data-counter', count);
        thumbContainer.setAttribute('href', '#');
    };

    /**
     *    Removes key blocking.
     */
    unBlockKeys() {
        this.blockKeys = false;
    };

    /**
     *    Selects a certain image. (You have to pass the outer container of the image)
     *    @param container
     *    @param forceReload
     */
    select(container, forceReload) {
        forceReload = typeof forceReload !== 'undefined' ? forceReload : false;

        if (this.blockKeys || container === null) {
            return false;
        }


        this.blockKeys = true;
        if ( this.selectedContainer !== undefined ) {
            //this prevents an ugly effect if you click on the currently selected item
            if (container == this.selectedContainer && !forceReload) {
                this.unBlockKeys();
                return false;
            }
            this.deselect(this.selectedContainer);
        }

        // handle URL
        if (history && history.pushState) {
            history.pushState('', '', addUrlHashParameter(window.location.href, 'imageno', this.thumbs.indexOf(container)));
        }
        //if target image is not on current page, we have to go there first
        let targetPage = Math.floor((container.getAttribute('data-counter') - this.imagesPerFirstPage) / this.imagesPerPage) + 1;

        if (this.currentPageNumber != targetPage) {
            this.gotoPage(targetPage, container);
        }
        this.selectedContainer = container;

        container.classList.add(this.options.activeClass);

        //first link in the container
        let source = container.children[0];

        // prepare the add2cart button
        if (this.options.showCartButton) {
            if (container.getElementsByTagName('A')[0]?.getAttribute('data-type') === 'image') {
                this.add2cartLink.setAttribute('data-id', source.getAttribute('data-id'));
                this.add2cartLink.style.display =  'block';
            } else {
                this.add2cartLink.style.display =  'none';
            }
        }

        if (this.options.showCartConnector) {
            this.cartConnectorLink.setAttribute('data-folder', source.getAttribute('data-folder'));
            this.cartConnectorLink.setAttribute('data-file', source.getAttribute('data-file'));
            this.cartConnectorLink.setAttribute('href', decodeURIComponent(source.getAttribute('data-cart-connector-link')));
        }

        if (this.options.showSocialMediaButton) {
            this.socialmediabutton.setAttribute('data-href', decodeURIComponent(source.getAttribute('data-social-sharing-link')) );
        }

        document.dispatchEvent(Polyfill.createNewEvent(Events.rebindAdd2CartButtons, null));

        // now lets set the image
        this.setImage(source);
    };


    /**
     * Selects an image by its thumbnail index.
     * @param {integer} index of the thumbnail, starting with 0
     */
    selectByIndex(index) {
        //this.mouseLeaveHandler();
        if (index < 0 || this.thumbs.length <= index) {
            index = 0;
        }
        this.select(this.thumbs[index]);
    };

    /**
     *    Opposite to method above.
     *    @param {HTMLHtmlElement} container
     */
    deselect(container) {
        container.classList.remove(this.options.activeClass);
    };

    bigImageClickHandler(e) {
        this.createGallerySlides();
        let currentIndex = this.thumbs.indexOf(this.selectedContainer);
        Eventgallery.lightbox.openPhotoSwipe(
            currentIndex, this.slides, true, false, false
        );
        Eventgallery.lightbox.setAfterChangeEventListener(() => {
            let gid = Eventgallery.lightbox.getCurrentSlide().gid;
            if (this.slides.length>0 && gid === this.slides[0].gid) {
                this.selectByIndex(Eventgallery.lightbox.getCurrentIndex());
            }
        });
    }
    /**
     *    Changes the full size image to given one.
     *    @param lightboxLinkElement
     */
    setImage(lightboxLinkElement) {

            this.bigImage.style.opacity = '0.1';
            let newSrc = lightboxLinkElement.getAttribute('rel');
            let title =  decodeURIComponent(lightboxLinkElement.getAttribute('data-description'));

            let currentIndex = this.thumbs.indexOf(this.selectedContainer);


            if (Eventgallery.lightbox?.isOpen() === true) {
               Eventgallery.lightbox.gotoSlide(currentIndex);
            }

            this.galleryElement.querySelector(this.options.titleTarget).innerHTML = title;

            this.bigImage.setAttribute('src', newSrc);
            this.unBlockKeys();
    };

    showBigImage() {
        this.image.style.opacity = 1;
        this.bigImage.style.opacity = 1;
    };

    /**
     *    Navigates to previous page.
     */
    prevPage() {
        this.gotoPage(this.currentPageNumber - 1);
    };
    /**
     *    Navigates to next page.
     */
    nextPage() {
        this.gotoPage(this.currentPageNumber + 1);
    };
    /**
     *    Selects the previous image.
     */
    prevImage(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        this.selectByIndex(this.thumbs.indexOf(this.selectedContainer) - 1);
    };
    /**
     *    Selects the next image.
     */
    nextImage(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        this.selectByIndex(this.thumbs.indexOf(this.selectedContainer) + 1);
    };

    /**
     * Zooms an image
     */
    zoomImage(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
    };

    /**
     *    Navigates to given page and selects the first image of it.
     *    Also hides the handles (if set).
     *    @param {Integer} pageNumber, index of the target page (0-n)
     *  @param {HTMLElement} selectImage, optionally receives a particular image to select
     */
    gotoPage(pageNumber, selectImage) {
        //if we like to select another image on that page than the first one
        if (pageNumber === 0) {
            selectImage = selectImage === undefined ? this.thumbs[0] : selectImage;
        } else {
            let i = (pageNumber - 1) * this.imagesPerPage + this.imagesPerFirstPage;
            selectImage = this.thumbs[i] === undefined ? selectImage : this.thumbs[i];
        }

        if (pageNumber >= 0 && pageNumber < this.lastPage) {
            this.pageContainer.style.marginLeft = (getElementWidth(this.pageContainer.children[0]) * pageNumber * -1) + 'px';
            this.currentPageNumber = pageNumber;
            this.select(selectImage);
            this.updateHandles();
        }
    };

    updateHandles() {
        //update handles

        if (this.options.prevHandle) {
            this.currentPageNumber === 0 ? this.options.prevHandle.style.opacity = 0:this.options.prevHandle.style.opacity = 1;
        }
        if (this.options.nextHandle) {
            this.currentPageNumber == this.lastPage - 1 ? this.options.nextHandle.style.opacity = 0:this.options.nextHandle.style.opacity = 1;
        }

        if (this.options.countHandle) {
            this.updatePagingBar(this.currentPageNumber, this.lastPage);
        }

    };

    updatePagingBar(currentPage, pageCount) {
        if (pageCount <= 1 ) {
            return;
        }

        //init the pagingbar
        if (pageCount > 1 && this.options.countHandle.innerHTML === '') {

            for (let i = 0; i < pageCount; i++) {
                this.createCountLink(i);
            }
        }

        let pageSpeed = this.options.pageSpeed;


        if (pageCount > 9) {

            for (let i = 0; i < pageCount; i++) {
                this.galleryElement.querySelector('#count' + i).style.display = 'inline';
            }

            let skipFromRight = pageCount;
            let skipFromLeft = 0;

            let spaceToRight = pageCount - currentPage - 1;
            let spaceToLeft = currentPage;

            if (spaceToLeft > 4 && spaceToRight > 4) {
                skipFromLeft = currentPage - 4;
                skipFromRight = currentPage + 5;
            } else {
                if (spaceToLeft <= 4) {
                    skipFromLeft = 0;
                    skipFromRight = currentPage + 5 + (4 - spaceToLeft);
                }
                if (spaceToRight <= 4) {
                    skipFromLeft = currentPage - 4 - (4 - spaceToRight);
                    skipFromRight = pageCount;
                }
            }

            for (let i = 0; i < skipFromLeft; i++) {
                this.galleryElement.querySelector('#count' + i).style.display = 'none';
            }

            for (let i = skipFromRight; i < pageCount; i++) {
                this.galleryElement.querySelector('#count' + i).style.display = 'none';

            }
        }

        forEach(this.options.countHandle.children, (child) => child.classList.remove('active'));
        this.galleryElement.querySelector('#count' + currentPage).classList.add('active');

    };

    createCountLink(currentPageNumber) {

        let myAnchor = document.createElement('A');
        myAnchor.href = '#';
        myAnchor.classList.add('page-link');
        myAnchor.innerText = currentPageNumber + 1;

        myAnchor.addEventListener('click',(e) => {
            e.preventDefault();
            this.gotoPage(currentPageNumber, null);
            return false;
        });

        let myListItem = document.createElement('LI');
        myListItem.classList.add('count');
        myListItem.classList.add('page-item');
        myListItem.id = 'count' + currentPageNumber;

        myListItem.appendChild(myAnchor);

        this.options.countHandle.appendChild(myListItem);
    }
}


